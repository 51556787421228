html, body {
  height: 100%;
  background-color: #0B0B0B;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}

p {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 100;
  margin: 0;
}

::-webkit-input-placeholder {
  font-size: 14px;
}

:-moz-placeholder { /* older Firefox*/
  font-size: 14px;
}

::-moz-placeholder { /* Firefox 19+ */
  font-size: 14px;
}

:-ms-input-placeholder {
  font-size: 14px;
}

input:focus {
  outline: none !important;
  border-color: #719ECE;
  box-shadow: 0 0 10px #719ECE;
}

textarea:focus, input:focus, input[type]:focus, .uneditable-input:focus {
  border-color: #7D7C7C;
  box-shadow: -1px -1px 0 #7D7C7C, 1px 1px 0 #7D7C7C, -1px 1px 0 #7D7C7C, 1px -1px 0 #7D7C7C;
  outline: 0 none;
}

